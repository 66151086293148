exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy_policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-tokusho-tsx": () => import("./../../../src/pages/tokusho.tsx" /* webpackChunkName: "component---src-pages-tokusho-tsx" */),
  "component---src-pages-website-service-main-mdx": () => import("./../../../src/pages/website_service_main.mdx" /* webpackChunkName: "component---src-pages-website-service-main-mdx" */),
  "component---src-pages-website-service-tos-mdx": () => import("./../../../src/pages/website_service_tos.mdx" /* webpackChunkName: "component---src-pages-website-service-tos-mdx" */),
  "component---src-templates-archive-index-tsx": () => import("./../../../src/templates/archive/index.tsx" /* webpackChunkName: "component---src-templates-archive-index-tsx" */),
  "component---src-templates-archive-month-tsx": () => import("./../../../src/templates/archive/month.tsx" /* webpackChunkName: "component---src-templates-archive-month-tsx" */),
  "component---src-templates-article-tsx-content-file-path-vercel-path-0-src-content-articles-2022-12-30-test-index-mdx": () => import("./../../../src/templates/article.tsx?__contentFilePath=/vercel/path0/src/content/articles/2022/12/30/test/index.mdx" /* webpackChunkName: "component---src-templates-article-tsx-content-file-path-vercel-path-0-src-content-articles-2022-12-30-test-index-mdx" */),
  "component---src-templates-tag-tsx": () => import("./../../../src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

